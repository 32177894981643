:root {
  --dark-blue: #0034ff;
  --yellow: #ffe700;
  --light-yellow: #fcf4a3;
  --gray: #767676;
  --space-mono: "Space Mono", monospace;
}

* {
  box-sizing: content-box;
}

body {
  font-family: "Source Serif Pro", serif;
  font-family: "Space Mono", monospace;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("hills-sm.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center calc(100% - 120px);
}

input {
  border: none;
  background-color: var(--yellow);
  color: var(--dark-blue);
  font-family: "Source Serif Pro", serif;
  font-size: 22px;
  font-weight: 700;
  padding: 3px 2px 3px 6px;
}

input:focus {
  outline: none;
}

input::selection {
  background: none;
}

.app {
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.quote {
  flex: 1;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

h1 {
  color: var(--dark-blue);
  font-family: "Source Serif Pro", serif;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 22px;
  flex: 1;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.7);
}

p {
  color: var(--gray);
  font-family: var(--space-mono);
  text-align: center;
}

p a {
  color: var(--gray);
}

.refresh-button {
  display: flex;
  color: var(--dark-blue);
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 0;
  background: var(--yellow);
  font-family: var(--space-mono);
  font-size: 16px;
}

.refresh-button:focus {
  outline: none;
}

.refresh-button:active {
  outline: none;
  text-decoration: underline;
}

.refresh-button svg {
  height: 40px;
  margin-left: 14px;
  width: 40px;
  fill: var(--dark-blue);
}

@media (min-width: 768px) {
  body {
    background-image: url("hills.svg");
    background-position: center calc(100% - 40px);
  }

  .refresh-button {
    background: none;
    font-size: 18px;
    position: fixed;
    top: 20px;
    right: 20px;
    width: auto;
  }

  .refresh-button svg {
    background-color: var(--yellow);
    padding: 10px;
    width: 35px;
    height: 35px;
  }

  .refresh-button:hover {
    text-decoration: underline;
  }

  p br {
    display: none;
  }

  h1 {
    font-size: 28px;
    padding-top: 160px;
  }

  input {
    font-size: 28px;
  }
}
